import React, { useEffect, useRef, useState } from 'react'
import { DatePicker } from 'antd'
import ReactInputMask from 'react-input-mask'
import './styles.scss'
import moment from 'moment'

interface CustomDatePickerProps {
  value?: moment.Moment
  onChange?: (date: moment.Moment | null) => void
  placeholder?: string
  disabledDate?: any
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  value,
  onChange,
  placeholder,
  disabledDate,
}) => {
  const ref = useRef(null)

  const [text, setText] = useState<string>()

  const onChangeValue = () => {
    setText(moment(value).format('DD.MM.YYYY'))
  }

  const change = (date: moment.Moment) => {
    date.set('hours', 12)
    onChange(date)
  }

  const handleBlur = () => {
    const date = moment(text, 'DD.MM.YYYY', true)
    if (validateDate(date)) {
      change(date)
    } else {
      onChangeValue()
    }
  }

  const validateDate = (d: moment.Moment) => {
    if (!d.isValid()) return false

    if (!disabledDate) return true

    if (disabledDate(d)) return false

    return true
  }

  useEffect(() => {
    if (value) onChangeValue()
  }, [value])

  return (
    <div className="datepicker">
      <ReactInputMask
        mask="99.99.9999"
        className="input"
        placeholder={placeholder}
        value={text}
        onChange={(e) => setText(e.target.value)}
        onBlur={handleBlur}
      />
      <div className="picker">
        <DatePicker
          ref={ref}
          placeholder="Дата народження"
          format="DD.MM.YYYY"
          showToday={false}
          value={value}
          onChange={(value) => change(value)}
          disabledDate={disabledDate}
        />
      </div>
    </div>
  )
}

export default CustomDatePicker
